<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { required } from 'vuelidate/lib/validators';
import { VueContentLoading, VclList } from 'vue-content-loading';

export default {
  locales: {
    pt: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pagamento',
      'Order Date': 'Data do Pedido',
      'Payment receipts': 'Enviar comprovante',

      'Items': 'Itens',
      'Item': 'Item',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Awaiting payment': 'Aguardando pagamento',
      'Payment confirmed': 'Pagamento confirmado',

      'Address': 'Endereço',
      'Send exactly value to make payment': 'Envie exatamente o valor para efetuar o pagamento',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',

      'Have a voucher?': 'Possui um voucher?',
      'Validate': 'Validar',
      'Voucher is required.': 'O voucher é obrigatório',
      'The voucher is invalid.': 'O voucher é inválido.',
      'This voucher has already been used.': 'Este voucher já foi utilizado.',

      'To validate your transaction, paste the transaction hash here': 'Para validar sua transação, cole aqui a hash da transação',
      'Send': 'Enviar',

      'This order is not yours.': 'Este pedido não é seu.',
      'XFB payment is not available for this order.': 'O pagamento com XFB não está disponível para este pedido.',
      'The informed transaction hash has already been used.': 'A hash de transação informada já foi utilizada.',
      'Transaction hash could not be registered, try again.': 'Não foi possível registrar a hahs da transação, tente novamente.',

      'Password is required.': 'A senha é obrigatória.',
      'Pay': 'Pagar',

      'This order is not available for payment or has already been approved.': 'Este pedido não está disponível para pagamento ou já foi aprovado.',
      'Your security PIN is incorrect.': 'Seu PIN de segurança está incorreto.',
      'You do not have enough balance to pay for this order.': 'Você não possui saldo suficiente para realizar o pagamento deste pedido.',
    },
    es: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pago',
      'Order Date': 'Data do Pedido',
      'Payment receipts': 'Enviar recibo',

      'Items': 'Itens',
      'Item': 'Item',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Awaiting payment': 'En espera de pago',
      'Payment confirmed': 'Pago confirmado',

      'Address': 'Dirección',
      'Send exactly value to make payment': 'Enviar exactamente el valor de hacer el pago',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',

      'Have a voucher?': '¿Tienes un cupón?',
      'Validate': 'Validar',
      'Voucher is required.': 'Se requiere el cupón',
      'The voucher is invalid.': 'El cupón no es válido.',
      'This voucher has already been used.': 'Este cupón ya ha sido utilizado.',

      'To validate your transaction, paste the transaction hash here': 'Para validar la transacción, pegar el hash transacción aquí',
      'Send': 'Enviar',

      'This order is not yours.': 'Este pedido no es tuyo.',
      'XFB payment is not available for this order.': 'XFB pago no está disponible para este fin.',
      'The informed transaction hash has already been used.': 'El hash transacción informada ya se ha utilizado.',
      'Transaction hash could not be registered, try again.': 'El hash de la transacción no se pudo registrar, intente nuevamente.',

      'Password is required.': 'Se requiere contraseña.',
      'Pay': 'Pagar',

      'This order is not available for payment or has already been approved.': 'Este pedido no está disponible para pago o ya ha sido aprobado.',
      'Your security PIN is incorrect.': 'Su PIN de seguridad es incorrecto.',
      'You do not have enough balance to pay for this order.': 'No tiene saldo suficiente para pagar este pedido.',
    }
  },
  components: { Layout, VueContentLoading, VclList },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,

      order: {
        id: this.$route.params.id,
        voucher: false,
        date: null,

        subtotal: 0,
        discount: 0,
        total: 0,

        items: null,
        status: null,

        payment: {
          name: 'Deposit',
          status: 'pending',
          total: null,
          address: null,
          qrcode: null,
          expiration: null,
        }
      },

      voucher: {
        loading: false,
        alert: {
          type: '',
          message: '',
        },
        code: '',
      },

      cash: {
        alert: { type: '', message: '' },
        loading: false,
        balance: 'R$ 0,00',
        password: '',
      }
    };
  },
  validations: {
    voucher: {
      code: { required },
    },
    cash: {
      password: { required },
    },
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get('wallet/order/' + this.order.id)
          .then(response => {
            if (response.data.status=='success') {
              this.order = response.data.order
              this.loading = false

              // if (response.data.order.payment.methods.cash) {
              //   if (response.data.order.payment.methods.cash.status == 'enabled') {
              //     this.getBalance()
              //   }
              // }
            } else {
              this.empty = true
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.errored = error
          })
      }
    },
    voucherSubmit() {
      this.$v.voucher.$touch();

      if (this.voucher.code) {
        this.voucher.loading = true

        api
          .post('wallet/order/voucher/' + this.order.id, {
            code: this.voucher.code,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.getOrder()
              this.voucher.loading = false
            } else {
              this.voucher.alert.type = 'alert-danger'
              this.voucher.alert.message = response.data.message
              this.voucher.loading = false
            }
          })
      }
    },
    getBalance() {
      api
        .get('wallet/balance/cash')
        .then(response => {
          if (response.data.status=='success') {
            this.cash.balance = response.data.balance
          }
        })
    },
    payBalance() {
      this.$v.cash.$touch();

      if (this.order.id && this.cash.password) {
        this.cash.loading = true

        api
          .post('wallet/order/payment/' + this.order.id, {
            password: this.cash.password,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.cash.password = ''
              this.$v.cash.$reset();

              this.getOrder()
              this.cash.alert.type = 'alert-success'
              this.cash.alert.message = ''
              this.cash.loading = false
            } else {
              this.cash.alert.type = 'alert-danger'
              this.cash.alert.message = response.data.message
              this.cash.loading = false
            }
          })
          .catch(error => {
            if (error) {
              this.cash.alert.type = ''
              this.cash.alert.message = ''
              this.cash.loading = false
            }
          })
          .finally(() => {
            this.cash.loading = false
          })
      }
    }
  },
  mounted() {
    this.getOrder()
    this.getBalance()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Order') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 col-xl-6 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty" v-html="message">{{ t('No records found.') }}</div>
            <div v-else>
              <div class="invoice-title">
                <h4 class="float-right font-size-15 p-1">
                  <div v-if="!loading">{{ t('Order') }} # {{ order.id }}</div>
                </h4>
                <div class="mb-4">
                  <img height="25px" src="@/assets/images/logo.png">
                </div>
              </div>
              <hr />

              <!-- <div v-if="order.payment.status === 'pending' && !loading && order.voucher" class="p-2">
                <b-form @submit.prevent="voucherSubmit">
                  <div v-if="voucher.alert.message" :class="'alert ' + voucher.alert.type">{{ voucher.alert.message }}</div>
                  <b-form-group id="code" :label="t('Have a voucher?')" label-for="code">
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <b-form-input id="code" v-model="voucher.code" type="text" autocomplete="off" v-mask="'XXX-XXX-XXX'" class="text-center font-size-16" :class="{ 'is-invalid': $v.voucher.code.$error }"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="voucher.loading == true || voucher.code=='' || voucher.code.length!=11" type="submit" variant="danger">
                          {{ t('Validate') }}
                          <b-spinner v-if="voucher.loading" small class="ml-2 align-middle" variant="danger" role="status"></b-spinner>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="$v.voucher.code.$error" class="invalid-feedback">
                      <span v-if="!$v.voucher.code.required">{{ t('Voucher is required.') }}</span>
                    </div>
                  </b-form-group>
                </b-form>
              </div> -->

              <div class="p-2">
                <h3 class="font-size-14">{{ t('Order Date') }}</h3>
                <vue-content-loading v-if="loading" :width="100" :height="7">
                  <rect x="40" y="0" rx="2" width="60" height="7" />
                </vue-content-loading>
                <div v-else>
                {{ order.date }}
                </div>
              </div>

              <div class="p-2 mt-3">
                <h3 class="font-size-14">{{ t('Items') }}</h3>
              </div>
              <div class="table-responsive">
                <vcl-list v-if="loading"></vcl-list>
                <table v-else class="table table-nowrap">
                  <thead>
                    <tr>
                      <th>{{ t('Item') }}</th>
                      <th></th>
                      <th class="text-right">{{ t('Value') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td,index) in order.items" :key="index">
                      <td>{{ td.item }}</td>
                      <td>x {{ td.qty }}</td>
                      <td class="text-right">{{ td.subtotal }}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-nowrap mt-4">
                  <tbody v-if="order.subtotal != order.total">
                    <tr>
                      <td>{{ t('VOUCHER') }}</td>
                      <td class="text-right">- {{ order.discount }}</td>
                    </tr>
                    <tr>
                      <td>{{ t('TOTAL') }}</td>
                      <td class="text-right">{{ order.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="p-2 mt-3">
                <h3 class="font-size-14">{{ t('Status') }}</h3>
              </div>
              <div class="table-responsive">
                <vcl-list v-if="loading"></vcl-list>
                <table v-else class="table table-nowrap">
                  <thead>
                    <tr>
                      <th>{{ t('Date') }}</th>
                      <th class="text-right">{{ t('Status') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td,index) in order.status" :key="index">
                      <td>{{ td.date }}</td>
                      <td class="text-right">{{ t(td.status) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                <b-tab v-for="(pay,method) in order.payment.methods" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div class="text-center mt-3">
                    <div v-if="method === 'pix'">
                      <div class="text-center">
                        <img style="width: 250px;" :src="'data:image/jpeg;base64,' + pay.base64"/>
                        <p>Leia o qrcode acima ou</p>
                        <ol class="text-left">
                          <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                          <li>Busque a opção de pagar com pix.</li>
                          <li>Copie e cole o seguinte código.</li>
                        </ol>

                        <div class="border rounded p-3 mb-3 text-left">
                          {{ pay.qrcode }}
                        </div>

                        <button class="btn btn-danger" v-clipboard:copy="pay.qrcode"><i class="bx bx-copy font-size-18 align-middle"></i> Copiar</button>
                      </div>
                    </div>
                    <div v-else class="row">
                      <p class="col-md-6 mb-0"><img class="mb-4" height="50px" :src="require('@/assets/images/payments/' + pay.image)"></p>
                      <p class="col-md-6 mb-0">
                        <a v-if="pay.status === 'enabled'" target="_blank" class="btn btn-success btn-block text-white" :href="pay.url">Clique para pagar<br>com {{ pay.name }}</a>
                        <a v-else target="_blank" class="disabled btn btn-success" href="">Clique para pagar com {{ pay.name }}</a>
                      </p>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>